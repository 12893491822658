import "./App.css";
import FairLaunchMachinery from "./FairLaunchMachinery";
import { Container, Typography } from "@material-ui/core";
import { ThemeProvider, createTheme } from "@material-ui/core";
import { LinearProgress } from "@material-ui/core";
import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";

const theme = createTheme({
  palette: {
    type: "dark",
  },
});

const App = () => {
  const [mintedNumber, setMintedNumber] = useState(0);
  
  async function getMintedNumber() {
    // const result = await fetch("https://api.jsonbin.io/b/61d10c11ea3bf568213b4910/2").then((data) => data.json());
    setMintedNumber(2247);
  }

  useEffect(() => {
    getMintedNumber();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Container style={{ marginTop: 100, paddingBottom: 100 }} maxWidth="md">
        <Typography
          variant="h1"
          color="textSecondary"
          style={{ marginBottom: 60 }}
          align="center"
        >
          MINTING NOW
        </Typography>
        <Typography
          variant="h3"
          color="textPrimary"
          style={{ marginBottom: 20 }}
          align="center"
        >
          Rucka's Nuckas NFT Collection
        </Typography>
        <img alt="NFT Banner" width="100%" src="./banner.png" />
        <div style={{ marginBottom: 110 }}>
        <FairLaunchMachinery />
        <Typography
          variant="h5"
          color="textPrimary"
          style={{ marginTop: 90 }}
          align="center"
        >
          { mintedNumber } / 6666 minted
        </Typography>
        <LinearProgress
          style={{ marginBottom: 10, height: 40 }}
          color="primary"
          value={ (mintedNumber * 100) / 6666 }
          variant="determinate"
        />
          <Typography
            variant="h4"
            color="textPrimary"
            style={{ marginBottom: 20, marginTop: 100 }}
            align="center"
          >
            How to get an NFT:
          </Typography>
          <iframe
            width="100%"
            height="480px"
            src="https://www.youtube.com/embed/XPJ_yr7ysEs"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <div style={{ textAlign: "center" }}>
          <Typography
            variant="h4"
            color="textPrimary"
            style={{ marginTop: 100 }}
            align="center"
          >
            NFTCalendar Interviews Rucka Rucka Ali
          </Typography>
          <a
            href="https://nftcalendar.io/news/ruckas-nuckas-nfts-interview"
            target="_blank"
            rel="noreferrer"
          >
            <img
              alt="nft-calendar-interview"
              width="100%"
              style={{ marginTop: 30 }}
              src="./nft-calendar-interview.png"
            />
          </a>
        </div>
        <Typography
          variant="h4"
          color="textPrimary"
          style={{ marginTop: 100, marginBottom: 20 }}
          align="center"
        >
          FAQs
        </Typography>
        <Accordion style={{ backgroundColor: "#813eee" }}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>How much does one Ruckas Nucka's NFT cost?</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ backgroundColor: "#111" }}>
            <Typography style={{ paddingTop: 10 }}>1 Sol.</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ backgroundColor: "#813eee" }}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>What is the pre-sale and how do I get it?</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ backgroundColor: "#111" }}>
            <Typography style={{ paddingTop: 10 }}>
              The pre-sale is now sold out. If you have any questions about it
              please refer to the discord.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ backgroundColor: "#813eee" }}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>How do I buy the NFT?</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ backgroundColor: "#111" }}>
            <Typography style={{ paddingTop: 10 }}>
              Watch the above tutorial video.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ backgroundColor: "#813eee" }}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>
              I just minted my NFT. How do I know if it's rare?
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ backgroundColor: "#111" }}>
            <Typography style={{ paddingTop: 10 }}>
              Look at the chart below to see how rare each trait of your NFT is.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ backgroundColor: "#813eee" }}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>I just minted my NFT. How do I find it?</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ backgroundColor: "#111" }}>
            <Typography style={{ paddingTop: 10 }}>
              It will be in your Phantom Wallet in the collectibles section.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ backgroundColor: "#813eee" }}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>How do I sell my NFT?</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ backgroundColor: "#111" }}>
            <Typography style={{ paddingTop: 10 }}>
              You can now list your minted NFTs for sale on solsea.io <br />{" "}
              Here is where you will list it:{" "}
              <a
                href="https://solsea.io/collection/61ccf8ea29b70794e90c9007"
                style={{ color: "#2de2de" }}
              >
                SolSea Collection
              </a>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ backgroundColor: "#813eee" }}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>I have a different question.</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ backgroundColor: "#111" }}>
            <Typography style={{ paddingTop: 10 }}>
              Join the discord and ask it in there:{" "}
              <a
                href="https://discord.gg/p9MDfxPR"
                style={{ color: "#2de2de" }}
              >
                Invite link
              </a>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <img
          alt="trait-rarity"
          width="100%"
          style={{ marginTop: 90, marginBottom: 40 }}
          src="./rarity-chart.png"
        />
        <hr />
        <Typography
          variant="h5"
          color="textPrimary"
          style={{ marginTop: 30, marginBottom: 20 }}
          align="center"
        >
          Proud partner of:
        </Typography>
        <a href="https://nftsolana.io/" target="_blank" rel="noreferrer">
          <div style={{ textAlign: "center" }}>
            <img alt="nft-sol-calendar" src="./nft-sol-calendar.png" />
            <br />
            <a href="https://nftsolana.io/" target="_blank" rel="noreferrer">
              NFT Sol Calendar
            </a>
          </div>
        </a>
      </Container>
    </ThemeProvider>
  );
};

export default App;
